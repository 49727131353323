/* eslint-disable no-restricted-globals */
import { useToast } from "@chakra-ui/react";
import valid from "card-validator";
import { Formik } from "formik";
import React from "react";
import { HiOutlineChevronLeft } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import * as Yup from "yup";
import SolidButton from "../components/buttons/solidButton";
import CardExpiryInputField from "../components/input/cardExpiryInputField";
import CardNumberInputField from "../components/input/cardNumberInputField";
import InputField from "../components/input/inputField";
import Container from "../components/layout/container";
import BottomSection from "../components/sections/bottomSection";
import MiddleSection from "../components/sections/middleSection";
import TopSection from "../components/sections/topSection";
import { constants } from "../constants/constants";
import checkoutState from "../recoil/checkoutState";
import payloadState from "../recoil/payloadState";
import { getIPAddress } from "../services/checkout";
import { sendPaymentRequest } from "../services/payments";
import { encryptData } from "../util/encryption";

function CardPage() {
  const toast = useToast();
  const navigate = useNavigate();
  const payload = useRecoilValue(payloadState);
  const [checkout, setCheckout] = useRecoilState(checkoutState);

  const initialValues = {
    name_on_card: "",
    number: "",
    securityCode: "",
    expiry: "",
    amount: payload.merchant_site_data.request_amount,
  };

  const validationSchema = Yup.object({
    name_on_card: Yup.string()
      .test(
        "test-number",
        "Name is invalid",
        (value) => valid.cardholderName(value).isValid
      )
      .required("Required"),
    number: Yup.string()
      .test(
        "test-number",
        "Credit card number is invalid",
        (value) => valid.number(value).isValid
      )
      .required("Required"),
    securityCode: Yup.string()
      .test(
        "test-number",
        "securityCode is invalid",
        (value) => valid.cvv(value).isValid
      )
      .required("Required"),
    expiry: Yup.string()
      .test("len", "Expiry is invalid", (value) => value?.length === 5)
      .test(
        "test-number",
        "Expiry is invalid",
        (value) => valid.expirationDate(value).isValid
      )
      .required("Required"),
    amount: Yup.string().required("Required"),
  });

  const onSubmit = async (values) => {
    try {
      // const ipAddress = await getIPAddress();
      const body = {
        ...checkout,
        amount: values.amount,
        card: {
          expiry: {
            month: values.expiry.split("/")[0],
            year: `20${values.expiry.split("/")[1]}`,
          },
          name_on_card: values.name_on_card,
          number: values.number.replace(/\s/g, ""),
          security_code: values.securityCode,
        },
        device: {
          browser: navigator.userAgent,
          browserDetails: {
            "3DSecureChallengeWindowSize": "FULL_SCREEN",
            acceptHeaders: "application/json",
            colorDepth: screen.colorDepth,
            javaEnabled: true,
            language: "en-US",
            screenHeight: screen.height,
            screenWidth: screen.height,
            timeZone: new Date().getTimezoneOffset(),
          },
          ipAddress: "",
        },
      };
      const response = await sendPaymentRequest({
        checkout_request_id: payload.checkout_request_id,
        payment_method_type: "card",
        token: payload.access_token,
        checkout: body,
      });
      setCheckout((prevState) => ({
        ...prevState,
        charge_request_id: response.chargeRequestID,
      }));
      if (response.auth_available === true)
        navigate("/process-payment", {
          state: {
            redirect: response.html,
          },
        });
      else {
        toast({
          description: "Please use a card that is 3DS enabled",
          status: "error",
          duration: constants.TOAST_DURATION,
          position: constants.TOAST_POSITION,
          isClosable: true,
        });
      }
    } catch (error) {
      navigate("/payment-failed", {
        state: {
          message: error.message,
          canRetry: false,
        },
      });
    } finally {
      // setSubmiting(false);
    }
  };

  return (
    <Container>
      <div>
        <TopSection />
        <MiddleSection />
        <div className="-mt-8 space-y-2 rounded-t-xl bg-white p-4 sm:p-6">
          <div className="relative">
            <Link to="/">
              <HiOutlineChevronLeft className="absolute inset-y-0 left-0 h-full w-5 cursor-pointer text-black" />
            </Link>
            <h2 className="text-center font-medium">{`Pay with ${checkout.payment_method_name}`}</h2>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(props) => (
              <form
                onSubmit={props.handleSubmit}
                className="mt-4 grid grid-cols-2 gap-x-4 gap-y-4 text-sm sm:text-base"
              >
                <div className="col-span-2">
                  <InputField
                    name="name_on_card"
                    type="text"
                    label="Full Name"
                    autoComplete="cc-name"
                  />
                </div>
                <div className="col-span-2">
                  <CardNumberInputField
                    name="number"
                    type="tel"
                    label="Card Number"
                    autoComplete="cc-number"
                  />
                </div>
                <div className="col-span-1">
                  <CardExpiryInputField
                    name="expiry"
                    type="text"
                    label="Expiry (MM/YY)"
                    autoComplete="cc-exp"
                  />
                </div>
                <div className="col-span-1">
                  <InputField
                    name="securityCode"
                    type="text"
                    label="CVV"
                    autoComplete="csc"
                  />
                </div>
                <div className="col-span-full mt-4">
                  <SolidButton
                    type="submit"
                    label="Confirm Details"
                    isSubmitting={props.isSubmitting}
                    disabled={props.isSubmitting}
                  />
                </div>
              </form>
            )}
          </Formik>
        </div>
        <BottomSection />
      </div>
    </Container>
  );
}

export default CardPage;
